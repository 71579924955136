
import i18n from "i18next";
import moment from "moment";
import 'moment/locale/pt';

const getLinksSplited = (path) => {
  let links = path.split("/");
  links[0] = "home";
  return links;
};


function getTime(timestamp) {
  return moment.unix(timestamp).format("H:mm")
}

// { isPeriod: false, startDate: Date(), endDate: Date() }
const getDate = (date) => {
  let startDate = moment.unix(date?.startDate).format("DD MMMM YYYY");

  if (!date?.isPeriod) {
    let month = startDate.split(' ')[1].charAt(0).toUpperCase() + startDate.split(' ')[1].slice(1);
    let result = startDate.split(' ')[0] + " " + month + " " + startDate.split(' ')[2];
    return result;
  }

  let endDate = moment.unix(date?.endDate).format("DD MMMM YYYY");
  let startJson = { day: startDate.split(' ')[0], month: startDate.split(' ')[1].charAt(0).toUpperCase() + startDate.split(' ')[1].slice(1), year: startDate.split(' ')[2] }
  let endJson = { day: endDate.split(' ')[0], month: endDate.split(' ')[1].charAt(0).toUpperCase() + endDate.split(' ')[1].slice(1), year: endDate.split(' ')[2] }

  if (startJson.month === endJson.month) {
    return `${startJson.day}-${endJson.day} ${startJson.month} de ${startJson.year}`;
  } else {
    return `${startJson.day} ${startJson.month.substring(0, 3)} - ${endJson.day} ${endJson.month.substring(0, 3)} de ${endJson.year}`;
  }

}

function getDateStringFormat(timestamp, month = false) {
  moment.locale(i18n.language);
  let startDate = moment.unix(timestamp).format("DD MMMM YYYY");
  let _month = startDate.split(' ')[1].charAt(0).toUpperCase() + startDate.split(' ')[1].slice(1);
  switch (i18n.language) {
    case 'pt':
      // return month ? startDate.split(' ')[0] + " de " + _month : startDate.split(' ')[0] + " de " + _month + " de " + startDate.split(' ')[2];
      return month ? startDate.split(' ')[0] + " " + _month : startDate.split(' ')[0] + " " + _month + " " + startDate.split(' ')[2];
    case 'en':
      return month ? _month + "  " + startDate.split(' ')[0] : _month + " " + startDate.split(' ')[0] + ", " + startDate.split(' ')[2];
  }
}

function getNumberOfDaysOnPeriod(startDate, endDate) {
  let difference = endDate - startDate;
  let days = Math.ceil(difference / (3600 * 24));
  return days;
}

function getDayRangeOnPeriod(startDate, endDate) {
  let array = []
  let timestamp = startDate;
  let totalDays = getNumberOfDaysOnPeriod(startDate, endDate);

  for (var i = 0; i < totalDays; i++) {
    array.push({ key: timestamp, format: getDateStringFormat(timestamp, true) })
    timestamp = timestamp + 86400 // 86400 -> 24h 
  }

  return array;
}

export {
  getDate, getTime, getDateStringFormat, getNumberOfDaysOnPeriod, getDayRangeOnPeriod, getLinksSplited
}