/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUser = /* GraphQL */ `
  query ListUser {
    listUser {
      items {
        sub
        username
        enabled
        status
        createAt
        given_name
        family_name
        name
        picture
        email
        groups
      }
    }
  }
`;
export const listGroup = /* GraphQL */ `
  query ListGroup {
    listGroup {
      items {
        groupName
        description
        userGroupId
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($input: GetUserInput) {
    getUser(input: $input) {
      sub
      username
      enabled
      status
      createAt
      given_name
      family_name
      name
      picture
      email
      groups
    }
  }
`;
export const getPrize = /* GraphQL */ `
  query GetPrize($input: GetPrizeInput) {
    getPrize(input: $input) {
      id
      name
      questions {
        question
        answers {
          answer
          correct
        }
      }
      statusCode
      message
      stockUpdated
    }
  }
`;
export const getBoModule = /* GraphQL */ `
  query GetBoModule($id: ID!) {
    getBOModule(id: $id) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBoModules = /* GraphQL */ `
  query ListBoModules(
    $filter: ModelBOModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBOModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        enabled
        order
        submodules {
          id
          featureId
          featureType
          name
          description
          enabled
          order
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWheel = /* GraphQL */ `
  query GetWheel($PK: String!, $SK: String!) {
    getWheel(PK: $PK, SK: $SK) {
      PK
      SK
      name
      description
      value
      availability
      createdAt
      updatedAt
    }
  }
`;
export const listWheels = /* GraphQL */ `
  query ListWheels(
    $PK: String
    $SK: ModelStringKeyConditionInput
    $filter: ModelWheelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWheels(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        name
        description
        value
        availability
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
