import { DashboardRounded, PeopleRounded, ChildCareRounded, EmojiEvents } from '@material-ui/icons';


const SideMenuStructer = [
    // {
    //     id: "81fa23b5-a653-448f-b939-30ee3d2525d2",
    //     label: "sidemenu-item-dashboard",
    //     icon: <DashboardRounded />,
    //     path: "/bo/dashboard",
    //     modules: []
    // },
    // {
    //     id: "cc8a9990-c781-4938-b5e3-8b3d1a37a65c",
    //     label: "sidemenu-item-users-config",
    //     icon: <PeopleRounded />,
    //     modules: [
    //         {
    //             id: "sidemenu-item-group",
    //             label: "sidemenu-item-group",
    //             icon: <PeopleRounded />,
    //             path: "/groups",
    //         },
    //         {
    //             id: "sidemenu-item-users",
    //             label: "sidemenu-item-users",
    //             icon: <PeopleRounded />,
    //             path: "/users",
    //         }
    //     ]
    // }
    {
        id: "cc8a9990-c781-4938-b5e3-8b3d1a37a65c",
        label: "sidemenu-item-wheel",
        icon: <EmojiEvents />,
        path: "/wheel",
        modules: []
    }
]

export default SideMenuStructer;