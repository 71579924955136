import { EmojiEvents } from '@material-ui/icons';

import WheelPrizeList from './Views/List';

export const Structer = {
    title: 'wheel-page-title',
    sections: [
        {
            id: 'list',
            index: 0,
            label: 'wheel-nav-prizes',
            icon: <EmojiEvents />,
            path: '/wheel',
            component: <WheelPrizeList />,
        }
    ],
}