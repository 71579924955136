import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import { ListWheels, UpdateWheel } from '../../../service/api';

export const WheelService = () => {

    const { triggerNotification } = useNotifications();


    const listWheels = async (params) => {
        try {
            const { data } = await ListWheels(params);
            return data.listWheels.items
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const updateWheel = async (params) => {
        try {
            const { data } = await UpdateWheel(params);
            return data.updateWheel
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }



    return { listWheels, updateWheel }
}