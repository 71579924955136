import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Switch, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save, Close } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { ServiceContext } from '../../../../../context/services/service-context';
import { useNotifications } from '../../../../../components/Shared/NotificationSnackbar';

import * as Utils from '../../../../../service/utils';
import Styles from './style';

const useStyles = makeStyles(Styles);

const MIN_PRIZES_VALUE = 0;
const MAX_PRIZES_VALUE = 1000000;
const MIN_MULTI_VALUE = 0;
const MAX_MULTI_VALUE = 1000000;

const WheelPrizeList = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { Wheel } = useContext(ServiceContext);
    const { t, i18n } = useTranslation();
    const { triggerNotification } = useNotifications()
    const [wheelPrizes, setWheelPrizes] = useState([]);
    const [wheelMulti, setWheelMulti] = useState([]);
    const [availabilityDays, setAvailabilityDays] = useState([]);

    const [isEditPrize, setIsEditPrize] = useState(false);
    const [isEditMulti, setIsEditMulti] = useState(false);


    useEffect(() => {
        getListWheels('PRIZES');
        getListWheels('MULTIPLIERS');
    }, []);


    useEffect(() => {
        let intervalPrize = null;
        let intervalMulti = null;

        if (!isEditPrize) {
            intervalPrize = setInterval(() => {
                getListWheels('PRIZES');
            }, 2000);
        } else {
            clearInterval(intervalPrize)
        }

        if (!isEditMulti) {
            intervalMulti = setInterval(() => {
                getListWheels('MULTIPLIERS');
            }, 2000);
        } else {
            clearInterval(intervalMulti)
        }

        return () => {
            clearInterval(intervalPrize);
            clearInterval(intervalMulti);
        }
    }, [isEditPrize, isEditMulti])




    useEffect(() => {
        if (wheelPrizes.length > 0) {
            filterAvailabilityDays(wheelPrizes);
        }
    }, [wheelPrizes]);


    const wheelPrizesFormik = useFormik({
        initialValues: {},
        onSubmit: values => {
        },
    });

    const wheelMultiFormik = useFormik({
        initialValues: {},
        onSubmit: values => {
        },
    });

    const udaptePrize = async (sk) => {
        const prize = {
            ...wheelPrizesFormik.values[sk],
            availability: JSON.stringify(wheelPrizesFormik.values[sk].availability)
        }
        const updatePrize = await Wheel.updateWheel(prize);
        if (updatePrize.PK) {
            triggerNotification("success", "notifications-wheel-update-prize-message-message", "notifications-wheel-update-prize-message-title");
        }
    };

    const udapteMulti = async (sk) => {
        const mutli = wheelMultiFormik.values[sk];
        const updateMulti = await Wheel.updateWheel(mutli);
        if (updateMulti.PK) {
            triggerNotification("success", "notifications-wheel-update-prize-message-message", "notifications-wheel-update-prize-message-title");
        }
    };


    const getListWheels = async (key) => {
        let listWheels = await Wheel.listWheels({ PK: key });
        switch (key) {
            case 'PRIZES': {
                listWheels.forEach(element => {
                    element.availability = JSON.parse(element.availability);
                });
                console.log(listWheels);
                buildFormikValues(listWheels, wheelPrizesFormik)
                setWheelPrizes(listWheels);
                break;
            };
            case 'MULTIPLIERS': {
                console.log(listWheels);
                buildFormikValues(listWheels, wheelMultiFormik)
                setWheelMulti(listWheels);
                break;
            };
        }


    }

    const filterAvailabilityDays = (data) => {
        let array;
        array = extractDays(data)
        array = removeDuplicates(array);
        array = sortArrayNumbers(array);
        setAvailabilityDays(array);
    }

    function concatArrays(arrays) {
        let array = [];
        arrays.forEach(element => {
            array = array.concat(element.availability);
        });
        return array;
    }

    function extractDays(arrays) {
        let array = [];
        arrays.forEach(element => {
            element.availability.forEach(element => {
                array.push(element.date);
            });
        });
        return array;
    }

    function removeDuplicates(array) {
        return array.filter((item, index) => array.indexOf(item) === index);
    }

    function sortArrayNumbers(array) {
        return array.sort()
    }

    function buildFormikValues(data, formik) {
        data.forEach((elem, index) => {
            formik.setFieldValue(`${elem.SK}`, elem);
        });
    }


    function addPrizMultiplayerCell(prize, status) {
        let input = [];

        if (status) {
            wheelMulti.map((prop, index) =>
                prop.SK === prize.SK ?
                    input.push(
                        <div key={`row-cell-multi-${index}`} className={classes.cell}>
                            <TextField
                                id={`${prize.name}`}
                                type='number'
                                variant="outlined"
                                m
                                value={
                                    wheelMultiFormik.values[prize.name] ?
                                        wheelMultiFormik.values[prize.name].value
                                        : null
                                }
                                onChange={onMultiHandleChange}
                                InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                                required
                            />
                        </div >
                    )
                    : null
            )
        } else {
            wheelMulti.map((prop, index) =>
                prop.SK === prize.SK ?
                    input.push(
                        <div key={`row-cell-multi-${index}`} className={classes.cell}>
                            {
                                wheelMultiFormik.values[prize.name] ?
                                    wheelMultiFormik.values[prize.name].value
                                    : null
                            }
                        </div>
                    )
                    : null
            )
        }


        if (input.length > 0) {
            return input;
        }
        return <div className={classes.cell}><span>--</span></div>
    }

    function onPrizeHandleChange(event) {
        let id = event.target.id;
        let value = event.target.value;
        // indexs[0] -> prize object id
        // indexs[1] -> prize availability id
        let indexs = event.target.id.split('@');
        let item = wheelPrizesFormik.values[indexs[0]];

        if (value >= MIN_PRIZES_VALUE && value <= MAX_PRIZES_VALUE) {
            item.availability[indexs[1]].count = value
            wheelPrizesFormik.setFieldValue(indexs[0], item);
        }
    }

    function onMultiHandleChange(event) {
        let id = event.target.id;
        let value = event.target.value;
        let item = wheelMultiFormik.values[id];

        if (value >= MIN_MULTI_VALUE && value <= MAX_MULTI_VALUE) {
            item.value = value
            wheelMultiFormik.setFieldValue(id, item);
        }
    }

    const onSwitchEditPrizeHandler = () => {
        if (!isEditMulti) {
            setIsEditPrize(!isEditPrize)
        }
    }

    const onSwitchEditMultiHandler = () => {
        if (!isEditPrize) {
            setIsEditMulti(!isEditMulti)
        }
    }

    return (
        <div className={classes.root}>


            <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', padding: '10px 0px 0px 0px', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div className={classes.cell} style={{ width: "auto" }}>
                        <span className={classes.headerTitle}>{t('wheel-swtich-label1')}</span>
                    </div>
                    <CustomSwitch checked={isEditPrize} onChange={onSwitchEditPrizeHandler} name="checkedPrize" />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'row', padding: '0px 0px 30px 0px', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <div className={classes.cell} style={{ width: "auto" }}>
                        <span className={classes.headerTitle}>{t('wheel-swtich-label2')}</span>
                    </div>
                    <CustomSwitch checked={isEditMulti} onChange={onSwitchEditMultiHandler} name="checkedMulti" />
                </div>
            </div>


            <div className={classes.form}>
                <div className={classes.header}>
                    <div className={classes.cell} style={{ width: '300px', display: 'flex', alignItems: 'center', borderRight: '2px solid #f0f0f0' }}>
                        <span className={classes.headerTitle}>{t('wheel-table-header-label1')}</span>
                    </div>
                    {
                        availabilityDays.length > 0 ?
                            availabilityDays.map((prop, index) =>
                                <div key={`header-cell-${index}`} className={classes.cell} style={{ borderRight: '2px solid #f0f0f0' }}>
                                    <span className={classes.headerTitle}>{Utils.getDateStringFormat(prop, true)}</span>
                                </div>
                            )
                            : null
                    }
                    <div className={classes.cell} style={{ borderRight: '2px solid #f0f0f0' }}>
                        <span className={classes.headerTitle}>{t('wheel-table-header-label2')}</span>
                    </div>
                </div>
                <div className={classes.container}>
                    {
                        wheelPrizes.length > 0 ?
                            wheelPrizes.map((prop, index) =>
                                <div key={`row-${index}`} style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                                    <div className={classes.cell} style={{ width: '300px', display: 'flex', justifyContent: 'flex-start', borderRight: '2px solid #f0f0f0' }}>
                                        <span>{prop.name}</span>
                                    </div>
                                    {
                                        isEditPrize ?
                                            availabilityDays.map((item, position) =>
                                                prop.availability.find(elem => elem.date === item) ?
                                                    <div key={`row-cell-${position}`} className={classes.cell}>
                                                        <TextField
                                                            id={`${prop.name}@${position}`}
                                                            type='number'
                                                            variant="outlined"
                                                            value={
                                                                wheelPrizesFormik.values[prop.name] ?
                                                                    wheelPrizesFormik.values[prop.name].availability[position].count
                                                                    : null
                                                            }
                                                            onChange={onPrizeHandleChange}
                                                            InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                                                            required
                                                        />
                                                    </div>
                                                    :
                                                    <div className={classes.cell}><span>--</span></div>
                                            )
                                            :
                                            availabilityDays.map((item, position) =>
                                                prop.availability.find(elem => elem.date === item) ?
                                                    <div key={`row-cell-${position}`} className={classes.cell}>
                                                        {
                                                            wheelPrizesFormik.values[prop.name] ?
                                                                wheelPrizesFormik.values[prop.name].availability[position].count
                                                                : null
                                                        }
                                                    </div>
                                                    :
                                                    <div className={classes.cell}><span>--</span></div>
                                            )
                                    }
                                    {
                                        addPrizMultiplayerCell(prop, isEditMulti)
                                    }
                                    {
                                        isEditPrize ?
                                            <div key={`row-cell-multi-${index}`} className={classes.cell}>
                                                <Button variant="contained" style={{ margin: "10px 0px", width: '60px' }} onClick={() => { udaptePrize(prop.SK) }}>
                                                    <Save />
                                                </Button>
                                            </div>
                                            : null
                                    }
                                    {
                                        isEditMulti ?
                                            <div key={`row-cell-multi-${index}`} className={classes.cell}>
                                                <Button variant="contained" style={{ margin: "10px 0px", width: '60px' }} onClick={() => { udapteMulti(prop.SK) }}>
                                                    <Save />
                                                </Button>
                                            </div>
                                            : null
                                    }

                                </div>
                            )
                            : null
                    }

                </div>
            </div>
            {/* <br></br> */}
            {/* <div>
                <Box className={classes.boxHeader}>
                    <Box className={classes.boxButton}>
                        <Button variant="contained" onClick={getPrize}>Get Prize</Button>
                    </Box>
                </Box>
                Is VDF:
                <CustomSwitch checked={checked} onChange={handleChange} name="checked"/>
                
                
                <div id="prize" className="display-linebreak"> 
                <h3>
                Prize: {prizeName} 
                </h3>
                </div>
            </div> */}
        </div>
    );
}

export default WheelPrizeList;



const CustomSwitch = withStyles((theme) => ({
    root: {
        height: 54,
        width: 74,
        padding: '10px'
    },
    switchBase: {
        padding: 0,
        height: 34,
        top: 9,
        left: 12,
        '&$checked': {
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#d0212a',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#d0212a',
        },
    },
    thumb: {
        width: 26,
        height: 26,
    },
    track: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 34 / 2,
        backgroundColor: '#cecece',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        height: 31,
        width: 51,
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
