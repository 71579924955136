/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      sub
      username
      enabled
      status
      createAt
      given_name
      family_name
      name
      picture
      email
      groups
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput) {
    createGroup(input: $input) {
      groupName
      description
      userGroupId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      statusCode
      message
    }
  }
`;
export const removeUserFromGroup = /* GraphQL */ `
  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput) {
    removeUserFromGroup(input: $input) {
      statusCode
      message
    }
  }
`;
export const createBoModule = /* GraphQL */ `
  mutation CreateBoModule(
    $input: CreateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    createBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBoModule = /* GraphQL */ `
  mutation UpdateBoModule(
    $input: UpdateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    updateBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBoModule = /* GraphQL */ `
  mutation DeleteBoModule(
    $input: DeleteBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    deleteBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const createWheel = /* GraphQL */ `
  mutation CreateWheel(
    $input: CreateWheelInput!
    $condition: ModelWheelConditionInput
  ) {
    createWheel(input: $input, condition: $condition) {
      PK
      SK
      name
      description
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateWheel = /* GraphQL */ `
  mutation UpdateWheel(
    $input: UpdateWheelInput!
    $condition: ModelWheelConditionInput
  ) {
    updateWheel(input: $input, condition: $condition) {
      PK
      SK
      name
      description
      value
    }
  }
`;
export const deleteWheel = /* GraphQL */ `
  mutation DeleteWheel(
    $input: DeleteWheelInput!
    $condition: ModelWheelConditionInput
  ) {
    deleteWheel(input: $input, condition: $condition) {
      PK
      SK
      name
      description
      value
      createdAt
      updatedAt
    }
  }
`;
