import React, { useState, useEffect } from 'react';
import { TextField, Paper, FormControlLabel, Switch, Button, Box, withStyles} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextFields, Subject } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Styles from './style';
import SubmitButton from '../../../components/Shared/SubmitButton';

import { ListWheels, UpdateWheel, GetPrize } from '../../../service/api';
import { useNotifications } from '../../../components/Shared/NotificationSnackbar';

const CustomSwitch = withStyles((theme) => ({
    root: {
        height: 54,
        width: 74,
        padding: '10px'
    },
    switchBase: {
        padding: 0,
        height: 34,
        top: 9,
        left: 12,
        '&$checked': {
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#34C759',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#34C759',
        },
    },
    thumb: {
        width: 26,
        height: 26,
    },
    track: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 34/2,
        backgroundColor: '#cecece',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
        height: 31,
        width: 51,
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#FFF",
        width: "100%",
        margin: "10px 0px 30px",
    },
    boxHeader: {
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
    },
    content: {
        padding: 32,
    },
    boxChipInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap"
    },
    chip: {
        margin: '5px'
    },
    input: {
        "font-size": '14px',
        "width": "50px"
    },
    elementDiv: {
        margin: "5px",
        float: "right"
    },
    prizesDiv: {
        "margin-right": "50px",
        float: "left",
        "width": "400px",
        display: "inline-block"
    },
    multipliersDiv: {
        float: "center",
        "width": "400px",
        display: "inline-block"
    },
}));

const Form = () => {
    const [prizeName, setPrizeName] = useState("the prize is...");
    const [prizes, setPrizes] = useState([]);
    const [multipliers, setMultipliers] = useState([]);
    const [checked, setChecked] = useState(false);

    const { triggerNotification } = useNotifications()



    useEffect(() => {
        listPrizeValues();
        listMultipliersValues();
    }, []);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        //onChange(event.target.checked)

        console.log(event)
    };

    const listPrizeValues = async () => {
        const data = {
            PK: "PRIZES"
        }
        let prizesResponse = await ListWheels(data);

        for (let i = 0 ; i < prizesResponse.data.listWheels.items.length; i++) {
            prizesFormik.values["prize" + i] = prizesResponse.data.listWheels.items[i].value;
        }

        setPrizes(prizesResponse.data.listWheels.items);
        console.log(prizesResponse);
    }

    const listMultipliersValues = async () => {
        const data = {
            PK: "MULTIPLIERS"
        }
        let multipliersResponse = await ListWheels(data);

        for (let i = 0 ; i < multipliersResponse.data.listWheels.items.length; i++) {
            formik.values["multiplier" + i] = multipliersResponse.data.listWheels.items[i].value;
        }

        setMultipliers(multipliersResponse.data.listWheels.items);
        console.log(multipliersResponse);
    }

    const getPrize = async () => {
        const data = {
            input: {
                isHappy: checked
            }
        }
        let prizeResponse = await GetPrize(data);

        console.log(prizeResponse);

        listPrizeValues();

        setPrizeName(prizeResponse.data?.getPrize?.name);
    }

    const handlePrizesSubmits = async (values) => {
        let valueChanged = false;
        for (let i = 0; i < Object.keys(values).length; i++) {
            let value = values[Object.keys(values)[i]];
        
            if (prizes[i].value != value) {
                valueChanged = true;
                await updateItem("PRIZES", i, value);
            }
        }

        if (!valueChanged) {
            prizesFormik.setSubmitting(false);
        } else {
            await listPrizeValues();
            triggerNotification("success", "Prizes update successful", "Sucess");
        }
    }

    const handleMultipliersSubmits = async (values) => {
        let valueChanged = false;
        for (let i = 0; i < Object.keys(values).length; i++) {
            let value = values[Object.keys(values)[i]];
        
            if (multipliers[i].value != value) {
                valueChanged = true;
                await updateItem("MULTIPLIERS", i, value);
            }
        }

        if (!valueChanged) {
            formik.setSubmitting(false);
        } else {
            await listMultipliersValues();
            triggerNotification("success", "Multipliers update successful", "Sucess");
        }
    }


    const prizesFormik = useFormik({
        initialValues: {},
        onSubmit: values => {
           handlePrizesSubmits(values);
        },
    });

    const formik = useFormik({
        initialValues: {},
        onSubmit: values => {

           handleMultipliersSubmits(values);

        },
    });

    const updateItem = async (PK, i, value) => {
        console.log("need update to value");

        const data = {
            input: {
                PK: PK,
                SK: multipliers[i].SK,
                value: value
            }
        }
        let multipliersResponse = await UpdateWheel(data);
        console.log(multipliersResponse);

        formik.setSubmitting(false);
        prizesFormik.setSubmitting(false);
    }


    const classes = useStyles();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.prizesDiv}>
                <form onSubmit={prizesFormik.handleSubmit}  enableReinitialize className={classes.form}>
                    <h3>Prizes:</h3>
                    {
                        prizes.map((prize, index) =>
                                <div className={classes.elementDiv}>
                                    <label style={{display: "inline-block",width: "300px"}}>{prize.SK}:</label>
                                    <input className={classes.input} align="center" type="text" id={"prize"+index} name={"prize"+index} value={prizesFormik.values["prize"+index]} onChange={prizesFormik.handleChange}/>          
                                </div>
                        )
                    }
                    <br></br>
                    <div style={{display: "inline-block",width: "100px", left: "5px", float: "right"}}><SubmitButton
                            isSubmitting={prizesFormik.isSubmitting}
                            label="Save"/>
                    </div>
                </form>
            </div>
   
            <div className={classes.multipliersDiv}>
                <form onSubmit={formik.handleSubmit}  enableReinitialize className={classes.form}>
                    <h3>Multipliers:</h3>
                    {
                        multipliers.map((multiplier, index) =>
                            <div className={classes.elementDiv}>
                                    <label style={{display: "inline-block",width: "300px"}}>{multiplier.SK}:</label>
                                    <input className={classes.input}  align="center" type="text" id={"multiplier"+index} name={"multiplier"+index} value={formik.values["multiplier"+index]} onChange={formik.handleChange}/>       
                                </div>
                        
                        )
                    }
                    <br></br>
                    <div style={{display: "inline-block",width: "100px", left: "5px", float: "right"}}><SubmitButton
                            isSubmitting={formik.isSubmitting}
                            label="Save"/>
                    </div>
                </form>
            </div>
            <br></br>
            <div>
                <Box className={classes.boxHeader}>
                    <Box className={classes.boxButton}>
                        <Button variant="contained" onClick={getPrize}>Get Prize</Button>
                    </Box>
                </Box>
                Is VDF:
                <CustomSwitch checked={checked} onChange={handleChange} name="checked"/>
                
                
                <div id="prize" className="display-linebreak"> 
                <h3>
                Prize: {prizeName} 
                </h3>
                </div>
            </div>
        </div>
    );
}

export default Form;