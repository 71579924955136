const Styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflow: 'hidden'
    },
    form: {
        // flex: 1,
        width: "1250px",
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',

    },
    header: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0px',
    },
    headerTitle: {
        // width: '133px',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        color: '#474747',
        fontSize: 18,
        fontWeight: 600,
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0px',

    },
    cell: {
        width: '113px',
        height: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 15px',
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A',
    },
})

export default Styles;