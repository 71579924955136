import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, InputAdornment, IconButton, Button, CircularProgress, Link, Typography } from '@material-ui/core';
import { EmailRounded, LockRounded, Visibility, VisibilityOff } from '@material-ui/icons';

import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import Assets from '../../../assets';
import Styles from './style';

const OFFICE_365_SIGNIN = false;

const useStyles = makeStyles(Styles);

const SignIn = () => {

    const { signin, signInFederated } = useContext(AuthContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [values, setValues] = useState({ showPassword: false });
    const { user } = getQueryStringsParams(['user']);

    const formik = useFormik({
        initialValues: {
            email: user ? user : '',
            password: ''
        },
        onSubmit: async values => {
            try {
                const response = await signin(values.email, values.password);
                const { challengeName } = response
                if (challengeName) {
                    switch (challengeName) {
                        case 'NEW_PASSWORD_REQUIRED':
                            navigate('/auth/new-password-required');
                            break;
                        default:
                            break;
                    }
                } else {
                    let from = location.state?.from
                    console.log(location)
                    navigate(from, { replace: true });

                }
            } catch (error) {
                throw error
            }
        },
    });

    const formikMicrosoft = useFormik({
        initialValues: {},
        onSubmit: async () => {
            try {
                await signInFederated("CycloidUsers", "backoffice");
            } catch (error) {
                throw error
            }
        },
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    return (
        <Container className={classes.main}>
            <Container className={classes.header}>
                <div className={classes.logo}>
                    <img src={Assets.Images.cyc_logo_full} alt="logo" />
                </div>
                <LanguageSelector />
            </Container>
            <Container className={classes.content} maxWidth='xl'>
                <div className={classes.contentHeader}>
                    <div className={classes.titleBox}>
                        <Typography variant="h1">
                            Prize Wheel
                        </Typography>
                    </div>

                    <div className={classes.bulletBox}>
                        <img src={Assets.Images.cy_bullets} alt="bullets" />
                    </div>

                </div>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('signin-text-title')}</div>
                    <div className={classes.subtitle}>{t('signin-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="email"
                            type='email'
                            placeholder={t('signin-input-email')}
                            variant="outlined"
                            InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                            defaultValue={formik.initialValues.email}
                            onChange={formik.handleChange}
                            required
                        />
                        <TextField
                            id="password"
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder={t('signin-input-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('signin-btn-text')
                        }</Button>
                    </form>
                    <span className={classes.subtext}>{t('signin-text-subtext-1')}&nbsp;&nbsp;
                        <Link component="button" onClick={() => navigate('/auth/recover-password')} className={classes.anchor}>{t('signin-text-subtext-2')}</Link>
                    </span>
                    {/*SIGNIN FEDERATION - OFFICE 365*/}
                    {
                        OFFICE_365_SIGNIN ?
                            <Button onClick={formikMicrosoft.handleSubmit} variant="contained" disableElevation disabled={formikMicrosoft.isSubmitting}>{
                                formikMicrosoft.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('signin-federation-office-btn-text')
                            }</Button>
                            : null
                    }
                </Paper>
            </Container>
            <Container className={classes.footer} maxWidth="xl">
                <span className={classes.cycText}>CYCLOID Technology and Consulting, Lda.</span>
                <span className={classes.cycPrivacy}>© All rights reserved.&nbsp;<a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchor} target="_blank">{t('signin-footer-text-subtext-2')}</a></span>
                <span className={classes.cycWebsite}>www.cycloid.pt</span>
            </Container>
        </Container>
    );
}

export default SignIn;




const getQueryStringsParams = (values) => {
    const urlParams = new URLSearchParams(window.location.search);
    let result = {}
    values.forEach(element => {
        result = {
            ...result,
            [element]: urlParams.get(element)
        }
    });
    return result;
}








// const multiStyle = (...args) => {
//     let styles = {};
//     for (var i = 0; i < args.length; {
//         styles = { ...styles, ...args[i] };
//     }
//     console.log(args);
//     return styles;
// }

// const multiClassNames = (...args) => {
//     let classes = "";
//     for (var i = 0; i < args.length; {
//         classes += args[i] + " "
//     }
//     console.log(classes);
//     return classes;
// }
