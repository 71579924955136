import React, { createContext } from 'react';


import { GroupAndUserService } from './GroupAndUsers/group-and-user-service';
import { WheelService } from './PrizesWheel';


export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

 
    const GroupAndUser = GroupAndUserService()
    const Wheel = WheelService();

    return (
        <ServiceContext.Provider value={{ GroupAndUser, Wheel }}> 
            { props.children}
        </ServiceContext.Provider>
    )
}

export default ServiceContextProvider;